import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import { Helmet } from "react-helmet"
import Img from "../../assets/images/bgHeroBusesUrbanos.jpg"
import { Wrapper } from "../../components/blogDesc/style"
const blog1 = () => {
  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Descripcion de blog"></meta>
      </Helmet>
      <Layout>
        <Hero
          img={Img}
          title="F&aacute;brica de carrocer&iacute;as l&iacute;der en el sur del Per&uacute;: Intramet, la historia"
        ></Hero>
        <Wrapper>
          <div className="container">
            <img src={Img} alt="Imagen Principal de blog" />
            <h2>
              F&aacute;brica de carrocer&iacute;as l&iacute;der en el sur del
              Per&uacute;: Intramet, la historia
            </h2>
            <p>
              El sector carrocero de Arequipa funcion&oacute; por generaciones
              acumulando experiencia en carrocer&iacute;as adaptables para las
              carreteras peruanas. Exist&iacute;an perfiles admirables de
              carroceros independientes tales como lo fue Agripino Coyuri.
            </p>
            <p>
              Mientras los carroceros del sur perfeccionaban sus
              t&eacute;cnicas, los del norte y de ciudades como Lima y Trujillo
              industrializaban su producci&oacute;n en serie logrando precios
              competitivos con veh&iacute;culos en serie captando as&iacute; la
              atenci&oacute;n del mercado nacional.
            </p>
            <h2>Inicio de una carrera en carrocer&iacute;as</h2>
            <p>
              Todo comenz&oacute; con Agripino Coyuri, quien se inici&oacute; a los 17 tiernos
              años en el mundo de las carrocer&iacute;as para el transporte de
              pasajeros. En el año 1967 Su primer centro de trabaj&oacute; fue la
              empresa “San Crist&oacute;bal Del Sur” donde desempeñando el cargo de
              ayudante.
            </p>
            <p>
              Al haber adquirido cierto grado de experiencia y en busca de m&aacute;s
              conocimiento (una caracter&iacute;stica que hered&oacute; a las futuras
              generaciones) pas&oacute; a formar parte de la empresa “Carrocer&iacute;as
              Espinar” ubicada en el Palomar - Arequipa, empresa dedicada a la
              fabricaci&oacute;n de carrocer&iacute;as.
            </p>
            <h2>Consolidaci&oacute;n de conocimiento y liderazgo</h2>
            <p>
              En los años 90 Agripino Coyuri consolida su alto grado de
              experiencia en la fabricaci&oacute;n de carrocer&iacute;as. Se convierte en Jefe
              de producci&oacute;n y pieza fundamental en la fundaci&oacute;n de Intramet S.A.{" "}
            </p>
            <p>
              Fueron ocho años de conocimiento, experiencias y alegr&iacute;as al ser
              parte de una empresa pr&oacute;spera que lo acogi&oacute; y confi&oacute; en &eacute;l para
              tener un cargo de responsabilidad.{" "}
            </p>
            <p>
              Por razones administrativas la empresa tuvo que cerrar sus
              puertas, lo que lejos de desanimar a nuestro fundador le dio la
              oportunidad de iniciar su etapa de emprendedor.{" "}
            </p>
            <h2>
            Etapa de emprendimiento
            </h2>
            <p>Pasar de t&eacute;cnico a jefe y finalmente animarse a emprender fue un camino que se fue dando de forma natural.</p>
            <p>En 1999 nuestro fundado pas&oacute; a formar parte de INMECOT, una sociedad empresarial dedicada a la fabricaci&oacute;n de carrocer&iacute;as. Pero esto no qued&oacute; all&iacute;, en busca de nuevas oportunidades y junto a su familia es que decidi&oacute; centrarse en algo propio. </p>
            <p>As&iacute; para el año 2002 lidera la fundaci&oacute;n de la empresa FECENTURY SRL. Fueron cuatro años en los que se aprendi&oacute; que para que un negocio prospere no s&oacute;lo debe tener el mejor producto, el crecimiento tambi&eacute;n debe apoyarse en una buena gesti&oacute;n administrativa y comercial. </p>
            <h2>Inicio de la F&aacute;brica de Carrocer&iacute;as l&iacute;der del sur del Per&uacute; </h2>
            <p>“Para ser la mejor empresa del sur del Per&uacute; no solo ten&iacute;an que ser los mejores carroceros, ten&iacute;an que adquirir visi&oacute;n empresarial”</p>
            <p>El 17 de Julio del 2006, de forma estrat&eacute;gica y aprovechando la coyuntura en el desarrollo y crecimiento del sector, se funda la empresa “INTRAMET S.R.L.” en el rubro de reparaci&oacute;n, mantenimiento y fabricaci&oacute;n de carrocer&iacute;as sobre chasis de las marcas m&aacute;s reconocidas tales como: SCANIA y MERCEDES BENZ.</p>
            <p>Se eligi&oacute; este nombre en honor a la primera f&aacute;brica que confi&oacute; en Agripino Coyuri para ser l&iacute;der de la l&iacute;nea de fabricaci&oacute;n. Y con mucho orgullo para el año 2011 se compr&oacute; la de Intramet, donde se encuentran hasta el d&iacute;a de hoy. </p>
            <h2>Etapa de evoluci&oacute;n, tiempo de reinventarse</h2>
            <p>Con el paso de los años cambiaron las expectativas del mercado y no bastaba tener &uacute;nicamente un veh&iacute;culo de buena calidad, ten&iacute;a que tener un diseño tan atractivo como los veh&iacute;culos importados. </p>
            <p>En esta necesidad de evolucionar para mantenerse competitivos es que nacen los primeros modelos de la serie Olimpo.</p>
            <p>El año 2016 fue el punto decisivo para Intramet. A ra&iacute;z de un incendio, se perdieron todos los moldes, las matrices de las carrocer&iacute;as. Fueron momentos dif&iacute;ciles, pero al mismo tiempo fue la oportunidad que necesitaban para innovar. </p>
            <p>Dejaron atr&aacute;s los patrones obsoletos y este evento fue la motivaci&oacute;n para darle paso a la reinvenci&oacute;n de diseños y acabados. </p>
            <p>Se renueva la serie Olimpo y toma fuerza y reconocimiento en el mercado. </p>
            <p>Durante los &uacute;ltimos años y algo m&aacute;s de experiencia administrativa y comercial se organizaron estrategias de posicionamiento y reconocimiento a los colaboradores </p>
            <p>Fue entonces que el 2018 se organiz&oacute; el evento de “Imposici&oacute;n de Cascos” para nombrar a los jefes de cada &aacute;rea. Este evento signific&oacute; la oportunidad de brindarles reconocimiento a nuestros colaboradores y entregarles cargos de confianza. </p>
            <p>En los siguientes años Intramet particip&oacute; de ferias para mostrar la calidad de los veh&iacute;culos, dando inicio a la fase comercial y de difusi&oacute;n. </p>
            <h2>¿Qu&eacute; es lo que trae el futuro para Intramet?</h2>
            <p>Somos una empresa que lleva en su ADN el emprendimiento y excelencia.</p>
            <p>Intramet se enorgullece de sus ra&iacute;ces y apuesta por capacitar y empoderar a sus trabajadores desde el inicio de sus actividades tanto en habilidades t&eacute;cnicas como blandas.</p>
            <p>Lo que viene a continuaci&oacute;n es m&aacute;s crecimiento con visi&oacute;n empresarial a largo plazo, llevar nuestros veh&iacute;culos al norte del Per&uacute;. Seguir evolucionando, seguirle el paso a este mercado competitivo. </p>

          </div>
        </Wrapper>
      </Layout>
    </>
  )
}

export default blog1
